.login h1 a {
    display: block;
    overflow: hidden;
    width: 326px;
    height: 67px;
    padding-bottom: 15px;
    text-indent: -9999px;
    background: url(../img/login-logo.png) no-repeat top center;
}

form {
    font-weight: normal;
    margin-left: 8px;
    padding: 26px 24px 46px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    background: white;
    box-shadow: none;
}

body form .input {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', sans-serif;
    font-size: 24px;
    font-weight: 200;
    width: 97%;
    margin-top: 2px;
    margin-right: 6px;
    margin-bottom: 16px;
    padding: 3px;
    border: 1px solid #e5e5e5;
    outline: none;
    background: #fbfbfb;
    box-shadow: inset 1px 1px 2px rgba(200, 200, 200, 0.2);
}
